<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="w-100 col-lg-8 d-lg-flex brand-logo">
        <vuexy-logo />

      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Lupa Password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Lupa Password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Lupa Password? 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Input alamat email dan kami akan mengirimkan intruksi untuk melakukan perubahan password
          </b-card-text>


          <validation-observer ref="simpleRules"  #default="{invalid}">
            <b-form class="auth-forgot-password-form mt-2">
              <b-form-group label="Email" label-for="forgot-password-email" v-if="!showPhoneForm">
                <validation-provider v-slot="{ errors }" name="Email" rules="required">
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="forgot-password-email"
                    placeholder="email@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Nomor Handphone" label-for="forgot-password-phone" v-if="showPhoneForm">
                <validation-provider v-slot="{ errors }" name="Phone" rules="required">
                  <b-form-input
                    id="forgot-password-phone"
                    v-model="userPhone"
                    :state="errors.length > 0 ? false : null"
                    name="forgot-password-phone"
                    placeholder="08xxxxxx"
                    pattern="08[0-9]*"
                    @keydown="restrictNonNumeric"
                  />
                  <!-- <small class="text-danger">Pastikan nomor handphone yang dimasukkan telah terdaftar di Aplikasi Whatsapp</small>
                  <br> -->
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button variant="secondary" block @click="showPhoneForm = true" v-if="!showPhoneForm">
                Kirim melalui nomor handphone
              </b-button>
              <b-button variant="secondary" block @click="showPhoneForm = false" v-if="showPhoneForm">
                Kirim melalui email
              </b-button>

              <b-button variant="primary" block @click="submitForm" :disabled="invalid">
                Kirim Tautan Atur Ulang
              </b-button>

            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> Kembali ke halaman login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Lupa Password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@/views/layouts/components/LogoDouble.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import {
  forgotPassword,
} from '@/api/user'
import { required, email } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      userPhone: '',
      showPhoneForm: false,
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
      localdata: {
        email: '',
        phone: '',
      },
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    restrictNonNumeric(event) {
      const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
      const numericKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

      if (!numericKeys.includes(event.key) && !allowedKeys.includes(event.key)) {
        event.preventDefault();
      }
    },
    async submitForm() {
      const response = await forgotPassword({email: this.userEmail, phone: this.userPhone})
      if (response.data.status === 'success') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'silahkan periksa email untuk link reset password!',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Email/No Handphone tidak ditemukan',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
